@import "./_layout", "./_color", "./_font";
@import "assets/fonts/pretendardvariable.css";
@import "assets/fonts/DMSans.css";
//common
html,
body {
    scroll-behavior: smooth;
}
button {
    cursor: pointer;
}
#main {
    * {
        font-family:
            "DM Sans",
            "Pretendard",
            "Noto Sans KR",
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            Roboto,
            "Helvetica Neue",
            Arial,
            sans-serif !important;
    }
    .material-icons {
        font-family: "Material Icons" !important;
    }

    .hidden {
        display: none !important;
    }
    main {
        section {
            position: relative;
            padding: 130px 0;
            width: 100%;
            // min-height: 100dvh;
        }
        .section-title {
            margin-bottom: 60px;
            width: fit-content;
            @include heading1;
            @include text-gradient;
            font-weight: bold;
            &.text-center {
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                & + .sub-title {
                    text-align: center;
                }
            }
            small {
                display: block;
                margin-bottom: 12px;
                @include sub-title;
                color: #767676;
                display: none;
            }
        }
        .sub-title {
            margin-top: -40px;
            margin-bottom: 60px;
            // text-align: center;
            @include body2;
            color: $gray900;
        }
        .title-box {
            margin-bottom: 60px;
            display: flex;
            gap: 48px;
            align-items: flex-start;
            .section-title,
            .sub-title {
                margin: 0;
                // flex: 1;
            }
            .section-title {
                white-space: nowrap;
            }
        }

        //section1
        #section1 {
            padding: 0;
            overflow: hidden;
            .main-visual {
                position: relative;
                aspect-ratio: 1920 / 880;
                background: url("../img/main_visual_bg2.png") no-repeat center top / contain;
                overflow: hidden;

                .main-text {
                    z-index: 10;
                    position: relative;
                    margin-top: 162px;
                    @include hero;
                    color: #fff;

                    span {
                        display: block;
                        overflow: hidden;
                        padding-top: 1.3em;
                        height: 1.2em;
                        transition: all 1s ease;
                    }

                    .l1 {
                        transition-delay: 0.1s;
                    }
                    .l2 {
                        transition-delay: 0.2s;
                    }
                    .l3 {
                        transition-delay: 0.3s;
                    }
                }

                .mockup {
                    z-index: 10;
                    position: absolute;
                    right: 11%;
                    bottom: -51%;
                    width: 42.5%;
                    opacity: 0;
                    transform: translate3d(0, 30px, 0);
                    transition: all 1s ease;
                }
            }
            .main-quote {
                position: relative;
                display: flex;
                padding: 44px 0 42px;
                align-items: center;
                justify-content: center;
                text-align: center;
                background: #fff;
                dt {
                    width: fit-content;
                    margin: 0 auto 16px;
                    @include heading2;
                    @include text-gradient;
                }
                dd {
                    @include body2;
                    color: $gray600;
                }
            }
            .partner-wrap {
                padding: 120px 0 40px;
                text-align: center;
                h3 {
                    margin-bottom: 40px;
                    @include heading2;
                    font-weight: 300;
                    color: $gray600;
                    strong {
                        font-weight: bold;
                        color: $black;
                    }
                }
            }

            &.active {
                .main-visual {
                    .mockup {
                        opacity: 1;
                        transform: translate3d(0, 0, 0);
                    }
                    .main-text {
                        span {
                            padding-top: 0;
                        }
                    }
                }
            }
        }

        //section2
        #section2 {
            padding: 0;
            aspect-ratio: 1920 / 1080;
            background: url("../img/consultation_portal_bg.png") no-repeat center / contain;
            &.active {
                .section-title,
                .sub-title {
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }

            .section-title {
                opacity: 0;
                padding-top: 6.4dvh;
                @include text-gradient;
                transform: translate3d(0, 10px, 0);
                transition: all 0.6s ease;
            }
            .sub-title {
                opacity: 0;
                transform: translate3d(0, 10px, 0);
                transition: all 0.6s ease;
            }
            // padding: 0;
            // height: 100dvh;
            // background: url("../img/abc.png") no-repeat right 0 bottom 64px;
            // background-attachment: fixed;
            // .abc-img {
            //     display: none;
            //     position: absolute;
            //     right: 0;
            //     bottom: 64px;
            // }
            // .container {
            //     display: flex;
            //     align-items: center;
            //     height: 100%;
            // }
            // .title {
            //     z-index: 10;
            //     position: relative;
            //     width: 564px;
            //     max-width: 100%;
            //     .brand-list {
            //         display: flex;
            //         gap: 12px;
            //     }
            //     padding-right: 80px;
            // }
            // .mobile-screens {
            //     z-index: 0;
            //     position: absolute;
            //     right: 150px;
            //     flex-basis: 954px;
            //     min-width: 954px;
            //     overflow: hidden;

            //     &::before,
            //     &::after {
            //         z-index: 100;
            //         content: "";
            //         display: block;
            //         width: 100%;
            //         position: absolute;
            //         left: 0;
            //         height: 40%;
            //     }
            //     &::before {
            //         top: 0;
            //         background: linear-gradient(0, rgba(255, 255, 255, 0) 73.41%, #fff 100%);
            //     }

            //     &::after {
            //         bottom: 0;
            //         background: linear-gradient(180deg, rgba(255, 255, 255, 0) 30.09%, #fff 96.74%);
            //         mix-blend-mode: hard-light;
            //     }
            //     .inner {
            //         display: flex;
            //         gap: 16px;
            //         height: fit-content;
            //         > div {
            //             flex: 1;
            //             display: flex;
            //             flex-flow: column;
            //             gap: 16px;

            //             &.col-left {
            //                 opacity: 33%;
            //             }
            //             &.col-center {
            //                 opacity: 66%;
            //             }
            //         }
            //     }

            //     .screen {
            //         border-radius: 12px;
            //         background: $bg-light;
            //         aspect-ratio: 375 / 812;
            //         padding: 20px;
            //     }
            // }
        }

        //section3
        #section3 {
            position: relative;
            padding-top: 80px;
            min-height: 0;
            background: url("../img/app_package_bg.png") no-repeat center / cover;
            .obj1,
            .obj2 {
                z-index: 10;
                position: absolute;
            }
            .obj1 {
                left: 10%;
                top: 60%;
                width: 7.4dvw;
            }
            .obj2 {
                right: 14%;
                bottom: -4%;
                width: 8.7dvw;
            }

            .card-wrap {
                display: flex;
                gap: 30px;

                .col {
                    perspective: 2000px;
                    border-radius: 40px;
                    // &-left {
                    // }
                    // &-center {
                    // }
                    &-right {
                        display: flex;
                        flex-flow: column;
                        gap: 30px;
                    }
                }

                .item {
                    position: relative;
                    perspective: 2000px;
                    height: 100%;
                    opacity: 0;
                    transform: translate3d(0, 0, -50px);
                    transition: all 0.6s ease;
                    img {
                        opacity: 1;
                        transform: rotate3d(0, 0, 0, 180deg);
                        transform-style: preserve-3d;
                        border-radius: 40px;
                        height: 100%;
                        object-fit: cover;
                        transition: all 0.6s ease;
                    }
                    .txt-box {
                        opacity: 0;
                        transform: rotate3d(0, 0.5, 0, 180deg);
                        transform-style: preserve-3d;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        padding: 40px;
                        border-radius: 40px;
                        transition: all 0.6s ease;
                        h3 {
                            margin-bottom: 20px;
                            @include sub-title;
                        }
                        p {
                            @include body2;
                            opacity: 0.5;
                        }
                    }

                    &:hover {
                        .txt-box {
                            opacity: 1;
                            transform: rotate3d(0, 0, 0, 180deg);
                        }
                        img {
                            opacity: 0;
                            transform: rotate3d(0, -0.5, 0, 180deg);
                        }
                    }
                    &.item1 .txt-box {
                        background: #c5ebf1;
                    }
                    &.item2 .txt-box {
                        background: #dadada;
                    }
                    &.item3 .txt-box {
                        background: #cfe6e4;
                    }
                    &.item4 .txt-box {
                        background: #e1e9ff;
                    }
                }
            }

            &.active {
                .card-wrap {
                    .item {
                        opacity: 1;
                        transform: translate3d(0, 0, 0);
                    }
                    .item1 {
                        transition-delay: 0s;
                    }
                    .item2 {
                        transition-delay: 0.2s;
                    }
                    .item3 {
                        transition-delay: 0.4s;
                    }
                    .item4 {
                        transition-delay: 0.6s;
                    }
                }
            }
        }

        //section4
        #section4 {
            min-height: 0;
            // overflow-x: hidden;
            background: url("../img/gradient_bubble_bg.png") no-repeat center top / 100% auto;
            background-attachment: fixed;
            // height: 200dvh;
            // .container {
            //     height: 100%;
            //     .flywheel-wrap {
            //         position: sticky;
            //         top: 160px;
            //     }
            // }
            .flywheel-wrap {
                position: relative;
                aspect-ratio: 1507/685;
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
                // &::before {
                //     content: "";
                //     opacity: 0.2;
                //     display: block;
                //     width: 100%;
                //     height: 100%;
                //     position: absolute;
                //     left: 0;
                //     top: 0;
                //     background: url("../img/flywheel_full.png") no-repeat center / contain;
                // }

                .flywheel-img {
                    z-index: 10;
                    position: relative;
                }
                &.active {
                    .txt-box {
                        opacity: 1;
                        transform: translate3d(0, 0, 0);
                    }
                    .box2 {
                        transition-delay: 0.2s;
                    }
                    .box6 {
                        transition-delay: 0.4s;
                    }
                    .box1 {
                        transition-delay: 0.6s;
                    }
                    .box3 {
                        transition-delay: 0.8s;
                    }
                    .box4 {
                        transition-delay: 1s;
                    }
                    .box5 {
                        transition-delay: 1.2s;
                    }
                }
                .txt-box {
                    z-index: 10;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 250px;
                    opacity: 0;
                    transform: translate3d(0, 10px, 0);
                    transition: all 0.8s ease;

                    h3 {
                        margin-bottom: 8px;
                    }

                    &.text-right {
                        text-align: right;
                        h3 {
                            margin-left: auto;
                        }
                    }
                    &.img-title {
                        left: 11.9%;
                        top: 0%;
                        opacity: 1;
                        transform: translate3d(0, 0, 0);

                        &::before {
                            content: "";
                            display: block;
                            width: 229px;
                            height: 229px;
                            position: absolute;
                            right: calc(100% + 40px);
                            top: calc(50% - 115px);
                            background: url("../img/flywheel_mini.png") no-repeat center / contain;
                        }
                        h3 {
                            color: #3f3f3f;
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 120%; /* 21.6px */
                            letter-spacing: -0.36px;
                            text-transform: uppercase;
                        }
                        p {
                            color: $gray900;
                            @include caption1;
                        }
                    }
                    &.type1 {
                        h3 {
                            width: fit-content;
                            @include text-gradient;
                            @include heading2;
                        }
                        p {
                            @include body2;
                            color: $gray900;
                        }
                    }
                    &.type2 {
                        h3 {
                            @include sub-title;
                            color: #3f3f3f;
                        }
                        p {
                            @include body2;
                            color: $gray900;
                        }
                    }

                    &.box1 {
                        width: 290px;
                        left: 78.7%;
                        top: 6.8%;
                    }
                    &.box2 {
                        width: 290px;
                        left: 0.4%;
                        top: 50%;
                    }
                    &.box3 {
                        width: 250px;
                        left: 81.5%;
                        top: 44.8%;
                    }
                    &.box4 {
                        width: 250px;
                        left: 74%;
                        top: 75.1%;
                    }
                    &.box5 {
                        width: 350px;
                        left: 0%;
                        top: 74.6%;
                    }
                    &.box6 {
                        width: 390px;
                        left: 3.8%;
                        top: 26.7%;
                    }
                }
            }
        }

        //section5
        #section5 {
            background: url(../img/teacher_app_bg.png) no-repeat center bottom / cover;
            background-attachment: fixed;

            &.active {
                .teacher-wrap {
                    .item {
                        h3 {
                            padding-top: 0;
                        }
                        p {
                            opacity: 1;
                            transform: translate3d(0, 0, 0);
                        }
                    }
                }
            }

            .teacher-wrap {
                display: flex;
                gap: 32px;
                .item {
                    flex: 1;
                    position: relative;
                    aspect-ratio: 518 / 671;
                    padding: 40px;
                    display: flex;
                    flex-flow: column;
                    overflow: hidden;

                    &.item1 {
                        background: url(../img/teacher_app_bg1.png) no-repeat center / contain;
                    }
                    &.item2 {
                        justify-content: flex-end;
                        background: url(../img/teacher_app_bg2.png) no-repeat center / contain;

                        .screen {
                            bottom: auto;
                            top: -40%;
                        }
                    }
                    &.item3 {
                        background: url(../img/teacher_app_bg3.png) no-repeat center / contain;
                    }

                    .screen {
                        position: absolute;
                        width: 61.7%;
                        left: 50%;
                        transform: translate3d(-50%, 0, 0);
                        bottom: -36%;
                    }
                }
                h3 {
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 12px;
                    @include sub-title;
                    color: $black;

                    line-height: 32px;
                    height: 32px;
                    overflow: hidden;
                    align-items: flex-start;
                    padding-top: 32px;
                    transition: all 0.6s ease;

                    // .icon {
                    //     position: absolute;
                    //     right: 100%;
                    //     top: -2px;
                    //     margin-right: 8px;
                    // }
                }
                p {
                    @include body2;
                    color: $gray900;
                    opacity: 0;
                    transform: translate3d(0, 30px, 0);
                    transition: all 0.6s ease 0.1s;
                }
            }
        }

        //section6
        #section6 {
            background: url("../img/user_flow_bg.png") no-repeat center / cover;
            // background-attachment: fixed;
            .user-flow-img {
                opacity: 0;
                transform: translate3d(0, 10px, 0);
                transition: all 0.6s ease;
            }
            &.active {
                .user-flow-img {
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
                }
            }
            .mobile-screens {
                position: relative;
                top: 32px;
                transition: all 0.6s ease;
                .inner {
                    display: flex;
                    gap: 16px;
                    height: fit-content;
                    > div {
                        flex: 1;
                        display: flex;
                        flex-flow: column;
                        gap: 16px;

                        &:nth-child(1),
                        &:nth-child(4) {
                            padding-top: 40px;
                        }
                    }
                }

                .screen {
                    border-radius: 12px;
                    background: $bg-light;
                    aspect-ratio: 1 / 1;
                    padding: 20px;
                    transition: all 0.6s ease;
                    &:nth-child(even) {
                        margin-top: 20px;
                    }

                    &.main-screen {
                        height: 650px;
                        position: absolute;
                        left: 50%;
                        top: 150px;
                        transform: translateX(-50%);
                        aspect-ratio: 333 / 692;
                        background: $bg-medium;
                        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
                        // transition: none;
                        margin-top: 0;
                    }
                }

                &.active {
                    top: 12px;
                    .screen {
                        margin-top: 0;

                        &.main-screen {
                            top: -40px;
                        }
                    }
                }
                &.deeper {
                    top: 0px;
                    .screen {
                        margin-top: 0px;

                        &.main-screen {
                            top: -100px;
                        }
                    }
                }
            }
        }

        //section7
        #section7 {
            padding: 0;
            min-height: calc(130px + 100dvh);
            overflow: hidden;
            background: url("../img/color_bg.png") no-repeat center / cover;

            .container {
                position: relative;
                height: 100%;

                .img-box {
                    position: absolute;
                    left: 0px;
                    top: 130px;
                    width: 100%;

                    .screen {
                        z-index: 20;
                        position: relative;
                        left: 20px;
                        img {
                            // width: 528px;
                            height: 100dvh;
                        }
                    }
                    .sub-content {
                        z-index: 10;
                        position: absolute;
                        right: 0;
                        top: 80px;
                    }
                    .component-list {
                        display: flex;
                        gap: 16px;
                        width: 1170px;

                        li {
                            transition: all 0.6s ease;
                        }
                        &.active {
                            li {
                                transform: translate3d(0, 0, 0) !important;
                            }
                        }

                        li:nth-child(1) {
                            z-index: 3;
                        }
                        li:nth-child(2) {
                            z-index: 2;
                            transform: translate3d(-100%, 0, 0);
                        }
                        li:nth-child(3) {
                            z-index: 1;
                            transform: translate3d(-200%, 0, 0);
                        }
                    }
                }

                .txt-box {
                    position: absolute;
                    right: 0;
                    padding-top: 64px;
                    width: 840px;
                    max-width: 100%;

                    p {
                        width: 774px;
                        max-width: 100%;
                        color: $gray600;
                    }

                    .color-list {
                        display: flex;
                        gap: 12px;
                        perspective: 2000px;

                        &.active {
                            li {
                                opacity: 1;
                                transform: translate3d(0, 0px, 0);
                            }
                        }

                        li {
                            display: flex;
                            flex-flow: column;
                            text-align: center;
                            border: 1px solid $l-regular;
                            flex-basis: 100px;
                            background: #fff;
                            opacity: 0;
                            transform: translate3d(0, 0, -100px);
                            transition: all 0.3s ease;
                            &::before {
                                content: "";
                                margin-bottom: 15px;
                                display: block;
                                width: 100%;
                                aspect-ratio: 1/1;
                                background: #efefef;
                            }
                            &.c1 {
                                transition-delay: 0.1s;
                                &::before {
                                    background: #ff5c5c;
                                }
                            }
                            &.c2 {
                                transition-delay: 0.2s;
                                &::before {
                                    background: #f7b305;
                                }
                            }
                            &.c3 {
                                transition-delay: 0.3s;
                                &::before {
                                    background: #80c720;
                                }
                            }
                            &.c4 {
                                transition-delay: 0.4s;
                                &::before {
                                    background: #00babb;
                                }
                            }
                            &.c5 {
                                transition-delay: 0.5s;
                                &::before {
                                    background: #5b89ff;
                                }
                            }

                            .name {
                                display: block;
                                margin-bottom: 4px;
                                @include caption1;
                                color: $black;
                                line-height: 1;
                            }
                            .value {
                                display: block;
                                margin-bottom: 15px;
                                @include caption2;
                                color: $gray900;
                                line-height: auto;
                            }
                        }
                    }
                }
            }
        }
        //section8
        #section8 {
            z-index: -1;
            padding: 0;
            aspect-ratio: 1920/1080;
        }

        //section9
        #section9 {
            background: url("../img/design_guide_bg.png") no-repeat center / cover;
            background-attachment: fixed;

            &.active {
                .design-wrap {
                    h3 {
                        transform: translate3d(0, 0, 0);
                    }
                    img {
                        transform: translate3d(0, 0, 0);
                    }
                    .g-color-wrap {
                        .g-color-img::after {
                            opacity: 1;
                        }
                    }
                }
            }
            .design-wrap {
                display: flex;
                flex-flow: column;
                gap: 64px;

                h3 {
                    margin-bottom: 20px;
                    @include sub-title;
                    color: #767676;
                    width: fit-content;
                    transform: translate3d(-100%, 0, 0);
                    transition: all 0.6s ease;
                }
                img {
                    transform: translate3d(-100%, 0, 0);
                    transition: all 1s ease;
                }
                .row {
                    display: flex;
                    align-items: flex-start;
                    gap: 56px;
                    > div {
                        overflow: hidden;
                    }
                }
                .color-wrap {
                    flex-basis: 71%;
                }
                .icon-wrap {
                    flex-basis: 29%;
                }
                .module-color-wrap {
                    flex-basis: 53%;
                }
                .typo-graphic {
                    flex-basis: 47%;
                    display: flex;
                    flex-flow: column;
                    gap: 53px;
                    justify-content: space-between;
                }
                .g-color-wrap {
                    width: 630px;
                    .g-color-img {
                        position: relative;
                        overflow-x: auto;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        // &::after {
                        //     content: "";
                        //     display: block;
                        //     position: absolute;
                        //     right: 0;
                        //     top: 0;
                        //     width: 20%;
                        //     height: 100%;
                        //     background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));

                        //     opacity: 0;
                        //     transition: all 1s ease 0.6s;
                        // }

                        img {
                            max-width: none;
                            height: 167px;
                        }
                    }
                }
            }
        }
    }

    #footer {
        padding: 12px 0;
        background: $bg-regular;
        .copy {
            @include caption1;
            color: $gray600;
        }
    }
}
