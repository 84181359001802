//font

@mixin hero {
    font-size: 72px;
    letter-spacing: -0.02em;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 900;
}
@mixin heading1 {
    font-size: 32px;
    letter-spacing: -0.02em;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 700;
}
@mixin heading2 {
    font-size: 24px;
    letter-spacing: -0.02em;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 700;
}
@mixin sub-title {
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 700;
}

@mixin body1 {
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 1.44;
}
@mixin body2 {
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 1.44;
}

@mixin caption1 {
    font-size: 12px;
    letter-spacing: -0.02em;
    line-height: 1.44;
}
@mixin caption2 {
    font-size: 10px;
    letter-spacing: -0.02em;
    line-height: 1.44;
}
@mixin text-gradient {
    background: var(
        --text1,
        linear-gradient(98deg, #5eb2ba -12.28%, #3f696a 17.42%, #4e4e4e 47.92%, #5a7576 83.05%, #8ad6da 108.13%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
