@import "./_font", "./_color";
#main {
    #nav {
        z-index: 1000;
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        width: 100%;
        height: 140px;
        align-items: center;
        border-bottom: 1px solid transparent;
        transition: all 0.6s ease;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100vw;
            height: 100%;
            transform: translate3d(0, -100%, 0);
            transition: all 0.6s ease;
        }

        .container {
            z-index: 100;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        #logo {
            padding-top: 50px;
            transition: all 0.6s ease;
            img {
                height: 40px;
                transition: all 0.6s ease;
            }
        }

        #menu {
            display: flex;
            gap: 28px;
            li {
                transform: translate3d(0, -20px, 0);
                opacity: 0;
                transition: all 0.6s ease-in-out;

                button {
                    color: $black;
                    text-decoration: none;
                    @include body2;
                    font-weight: 600;
                    border: 0;
                    background: none;
                }
            }
        }

        &.on {
            &.scrolling {
                #logo {
                    padding-top: 0;
                    img {
                        height: 28px;
                    }
                }
                height: 60px;
                // border-bottom: 1px solid $l-regular;
                &::before {
                    transform: translate3d(0, 0, 0);
                    background: rgba(255, 255, 255, 0.92);
                    backdrop-filter: blur(4px);
                }
                #menu {
                    li {
                        transform: translate3d(0, 0, 0);
                        opacity: 1;
                    }
                }
            }
        }
    }
}
